
import { defineComponent } from "vue";

export default defineComponent({
    name: "filterTags",
    props: {
        filters: Object,
        payload: Object,
        erasable: Boolean
    },
    emits: ["removedTag"],
    components: {},
    data() {
        return {
            activeFilters: this.$props.filters as any,
            filtersPayload: this.$props.payload as any,
            isErasable: this.$props.erasable as any,
        };
    },
    methods: {
        removeFilter(filter) {
            this.activeFilters.map((active_filter: any) => {
                if (active_filter.id === filter.id) {
                    this.activeFilters.splice(this.activeFilters.indexOf(filter), 1);
                    delete this.filtersPayload[filter.key];
                }
            });
            this.$emit("removedTag", [this.filtersPayload, this.activeFilters]);
        },
    },
});
