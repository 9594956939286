
import { defineComponent, ref } from "vue";
import dashboardApi from "@/core/services/DashboardApi";
import membersApi from "@/core/services/MembersApi";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import promotionsApi from "@/core/services/PromotionsApi";
import { Form, Field } from "vee-validate";
import { object, string } from "yup";
import { hideModal } from "@/core/helpers/dom";
import Filters from "../crafted/widgets/Filters.vue";
import FilterTags from "../crafted/widgets/FilterTags.vue";

export default defineComponent({
    name: "DashboardPromotions",
    emits: ["getPromNb", "refresh"],
    components: { Form, Field, Filters, FilterTags },
    props: {
        refreshChild: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            promotions: [],
            pages: [] as any,
            totalPages: "",
            currentPage: 1,
            selectedPromotions: [] as any,
            actualFilter: "",
            actualCol: "",
            loading: true,
            promotionSearch: "",
            progressDialog: false,
            nominationValues: [] as any,
            nominationKey: 0,
            percentage: 0,
            promotionsCount: "",

            perm_pos: "",
            perimeters: [
                {
                    label: "CDR",
                    value: "CDR",
                },
                {
                    label: "OMGD",
                    value: "OMGD",
                },
            ],
            grades: [] as any,
            dueLabel: "",
            memberId: "",
            priceCategories: [] as any,
            price_cat_options: [] as any,
            editingPromotion: false,
            promotionEntry: {
                id: null as any,
                member: null as any,
                date: null as any,
                type: null as any,
                grade: null as any,
                price_category: null as any,
            } as any,
            promotionAmount: "",
            promotionTypes: [],
            promotionPriceCats: [],
            disablePromotionType: false,
            activeFilters: {},
            filtersPayload: {},
            tagsKey: 0,
            filtersKey: 0,
            filtersToShow: {
                age: false,
                bailliage: false,
                compactBailliage: true,
                commander: false,
                country: false,
                dues: false,
                function: false,
                gender: false,
                grade: false,
                medal: false,
                member_type: false,
                other_association: false,
                period: true,
                postal_code: false,
                state: false,
                title: false,
                activity_sector: false,
                plaque: false,
                credit_card: false,
                institution_type: false,
                food_style: false,
                stars: false,
                price_category_promotion: false,
                price_category_due: true,
                type_due: false,
                relevant_due_year: false,
                type_chapitre: false,
                amount_accr: false,
                type_accr: false,
                biller_period: false,
                biller_balances: false,
            },
        };
    },
    setup() {
        // Number of items per page
        const resultsPerPage = 10;
        const router = useRouter();
        const promotionModalRef = ref<null | HTMLElement>(null) as any;

        const closePromotionModal = () => {
            hideModal(promotionModalRef.value);
        };

        const promotionSchema = object().shape({
            promotionDate: string().label("Promotion date").nullable(),
            promotionType: string()
                .required()
                .label("Promotion type")
                .nullable(),
            promotionGrade: string().required().label("Grade").nullable(),
            promotionPriceCat: string()
                .required()
                .label("Price category")
                .nullable(),
        });

        function createDebounce() {
            let timeout: any;
            return function (fnc, delayMs) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, delayMs || 500);
            };
        }

        return {
            resultsPerPage,
            router,
            debounce: createDebounce(),
            closePromotionModal,
            promotionModalRef,
            promotionSchema,
        };
    },
    created() {
        this.getPromotions();
        this.getPriceCategories();
        this.perm_pos = localStorage.getItem("perm_pos") as any;
    },
    methods: {
        editEntry(e: any) {
            if (this.perm_pos === "0") {
                this.memberId = e.member;

                if (e.resourcetype === "PromotionRequest") {
                    document.getElementById("openPromModalProm")?.click();
                    promotionsApi
                        .getSpecificPromotion(e.object.id)
                        .then((res: any) => {
                            if (res.success) {
                                this.editPromotion(
                                    res.data,
                                    res.data.member.id
                                );
                            }
                        });
                }
            }
        },
        getPriceCategories() {
            membersApi
                .getPriceCategories({ p: 1, presult: 9999 })
                .then((res) => {
                    this.priceCategories = this.price_cat_options =
                        res.data.object_list;
                    this.priceCategories.sort((a: any, b: any) =>
                        a.amount > b.amount ? 1 : -1
                    );
                });
            promotionsApi
                .getPromotionsPriceCategories({ p: 1, presult: 9999 })
                .then((res) => {
                    this.promotionPriceCats = res.data.object_list;
                    this.promotionPriceCats.sort((a: any, b: any) =>
                        a.amount > b.amount ? 1 : -1
                    );
                });
            membersApi.getGrades({ p: 1, presult: 9999 }).then((res) => {
                this.grades = res.data.object_list;
            });
        },

        setPromotionAmount(e: any) {
            this.promotionPriceCats.map((item: any) => {
                if (item.id === e) {
                    this.promotionAmount = item.amount;
                }
            });
        },
        editPromotion(promotion: any, memberId: any) {
            promotionsApi
                .getPromotionsPriceCategories({ p: 1, presult: 9999 })
                .then(() => {
                    // this.dueTypes = res.data.object_list;
                    this.promotionEntry = {
                        id: promotion.id,
                        member: memberId,
                        type: promotion.type.label,
                        grade: promotion.grade.id,
                        price_category: promotion.price_category.id,
                        date: promotion.date,
                    };

                    this.promotionAmount = promotion.price_category.amount;
                });
        },

        updatePromotion() {
            const promotionID = this.promotionEntry.id;

            delete this.promotionEntry.type;
            delete this.promotionEntry.id;

            promotionsApi
                .editPromotion(promotionID, this.promotionEntry)
                .then((res: any) => {
                    if (res.success) {
                        this.getPromotions();
                        this.closePromotionModal();
                        ElMessage({
                            type: "success",
                            message: "Successfully edited promotion.",
                        });
                    }
                });
        },
        goToMember(memberId: any) {
            const routeData = this.router.resolve({
                name: "member-details",
                params: { member_id: memberId, page: 1 },
            });
            window.open(routeData.href, "_blank");
        },
        emitCount() {
            this.$emit("getPromNb", this.promotionsCount);
            const payload = {
                p: 1,
                presult: 99999,
                filters: {
                    tab: "promotion",
                },
            };
            dashboardApi.list(payload).then((res: any) => {
                this.promotions = res.data.object_count;
                this.$emit("getPromNb", this.promotionsCount);
                this.$emit("refresh");
            });
        },
        handleSizeChange() {
            this.getPromotions();
        },
        setProgress(e: any) {
            this.percentage = e;

            if (this.percentage === 100) {
                setTimeout(() => {
                    this.progressDialog = false;
                    this.percentage = 0;
                }, 1000);
            }
        },
        hasAnyFilters() {
            for (const [key, value] of Object.entries(this.filtersPayload)) {
                if (value !== "") return true;
            }
            return false;
        },
        queryChanged(query: string) {
            this.promotionSearch = query;
            this.getPromotions();
        },
        getFiltersPayload(e: any) {
            this.tagsKey++;
            this.filtersPayload = e[0];
            this.activeFilters = e[1];

            var fp = JSON.stringify(this.filtersPayload);
            var f = JSON.stringify(this.activeFilters);

            localStorage.setItem("filtersPayload", fp);
            localStorage.setItem("activeFilters", f);

            this.getPromotions();
        },
        getPromotions() {
            var payload;
            this.loading = true;
            this.selectedPromotions = [];
            this.promotions = [];

            if (this.hasAnyFilters()) {
                if (this.actualFilter && this.actualCol) {
                    // Si filtres & tri
                    payload = {
                        p: this.currentPage,
                        presult: this.resultsPerPage,
                        status: "active",
                        filters: {
                            tab: "promotion",
                            ...this.filtersPayload,
                        },
                        column: this.actualCol,
                        order: this.actualFilter,
                        tab: "promotion",
                    };
                } else {
                    // Si filtres et pas de tri
                    payload = {
                        p: this.currentPage,
                        presult: this.resultsPerPage,
                        status: "active",
                        filters: {
                            tab: "promotion",
                            ...this.filtersPayload,
                        },
                    };
                }
            } else if (this.actualFilter && this.actualCol) {
                // Si tri et pas de filtres
                payload = {
                    p: this.currentPage,
                    presult: this.resultsPerPage,
                    status: "active",
                    column: this.actualCol,
                    order: this.actualFilter,
                    filters: {
                        tab: "promotion",
                    },
                };
            } else {
                // Sinon on affiche tout
                payload = {
                    p: this.currentPage,
                    presult: this.resultsPerPage,
                    status: "active",
                    filters: {
                        tab: "promotion",
                    },
                };
            }

            if (this.promotionSearch) {
                payload = { ...payload, query: this.promotionSearch };
            }
            dashboardApi.list(payload).then((res: any) => {
                this.promotions = res.data.object_list;
                this.promotionsCount = res.data.object_count;
                this.$emit("getPromNb", res.data.object_count);
                this.totalPages = res.data.page_count;
                this.loading = false;
            });
        },
        handleRequest(action: string, type: string, id?: number) {
            var payload;

            if (type === "row") {
                payload = [];
                this.promotions.map((row: any) => {
                    if (row.id === id) {
                        row.requests.map((request: any) => {
                            payload.push(request.id);
                        });
                    }
                });
            } else {
                id ? (payload = [id]) : (payload = this.selectedPromotions);
            }

            if (action === "accept") {
                dashboardApi.accept(payload).then((res: any) => {
                    if (res.success) {
                        type === "row"
                            ? this.removeRow("accepted", id)
                            : this.removeRequest(id, "accepted");
                        this.emitCount();
                    }
                });
            } else if (action === "refuse") {
                dashboardApi.refuse(payload).then((res: any) => {
                    if (res.success) {
                        type === "row"
                            ? this.removeRow("refused", id)
                            : this.removeRequest(id, "refused");
                        this.emitCount();
                    }
                });
            }
        },
        handleAllRequests(action: string) {
            var payload = [] as any;

            this.promotions.map((row: any) => {
                row.requests.map((request: any) => {
                    payload.push(request.id);
                });
            });

            if (action === "accept") {
                dashboardApi.accept(payload).then((res: any) => {
                    if (res.success) {
                        this.promotions = [];
                        ElMessage({
                            message: "All requests have been accepted.",
                            type: "success",
                        });
                        this.emitCount();
                    }
                });
            } else if (action === "refuse") {
                dashboardApi.refuse(payload).then((res: any) => {
                    if (res.success) {
                        this.promotions = [];
                        ElMessage({
                            message: "All requests have been refused.",
                            type: "success",
                        });
                        this.emitCount();
                    }
                });
            }
        },
        removeRequest(id: any, action: string) {
            this.promotions.map((row: any) => {
                row.requests = row.requests.filter(
                    (request: any) => request.id !== id
                );
                if (row.requests.length === 0)
                    this.promotions = this.promotions.filter(
                        (row: any) => row.requests.length !== 0
                    );
            });
            ElMessage({
                message: "Request " + action + ".",
                type: "success",
            });
        },
        removeRow(action: string, id?: any) {
            if (id) {
                this.promotions = this.promotions.filter(
                    (row: any) => row.id !== id
                );
                ElMessage({
                    message: "Requests group " + action + ".",
                    type: "success",
                });
            } else {
                this.promotions = [];
                ElMessage({
                    message: "All items have been " + action + ".",
                    type: "success",
                });
            }
        },
        sortColumn(column: string, id: number) {
            var arrows = document.getElementById("chevrons-prom-" + id);

            // Hide all arrows
            Array.from(
                document.getElementsByClassName("chevrons-container") as any
            ).map((filter: any) => {
                filter.style.display = "none";
            });

            // Display the right arrows
            if (arrows) arrows.style.display = "flex";

            // If we click for the first time on a column OR if we change the column
            if (this.actualCol === "" || this.actualCol !== column) {
                this.actualCol = column;
                this.actualFilter = "asc";
                this.handleChevron("show", "up");
                this.handleChevron("hide", "down");

                // Else if we click on the same column
            } else if (this.actualCol === column) {
                if (this.actualFilter === "asc") {
                    this.handleChevron("hide", "up");
                    this.handleChevron("show", "down");
                    this.actualFilter = "desc";
                } else if (this.actualFilter === "desc") {
                    this.handleChevron("show", "up");
                    this.handleChevron("hide", "down");
                    this.actualFilter = "asc";
                }
            }
            this.getPromotions();
        },
        handleChevron(action: string, direction: string) {
            var chevrons: any;

            if (direction === "up")
                chevrons = Array.from(
                    document.getElementsByClassName("fa-chevron-up") as any
                );
            else if (direction === "down")
                chevrons = Array.from(
                    document.getElementsByClassName("fa-chevron-down") as any
                );

            if (action === "show")
                chevrons.map((chevron: any) => {
                    chevron.classList.add("active-chevron");
                });
            else if (action === "hide")
                chevrons.map((chevron: any) => {
                    chevron.classList.remove("active-chevron");
                });
        },
        checkAll() {
            var topCheckbox = document.getElementById("topCheckboxProm") as any;
            var checkboxes = Array.from(
                document.getElementsByClassName("custom-checkbox-prom") as any
            );

            this.selectedPromotions = [];

            if (topCheckbox.checked) {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = true;
                });
                this.promotions.map((promotion: any) => {
                    this.selectedPromotions.push(promotion);
                });
            } else {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = false;
                });
                this.selectedPromotions = [];
            }
        },
        handlePromotionSelect(promotion: any) {
            var focusedCheckbox = document.getElementById(
                "checkbox-prom-" + promotion.id
            ) as any;

            if (focusedCheckbox?.checked) {
                this.selectedPromotions.push(promotion);
            } else {
                this.selectedPromotions.map((user: any) => {
                    if (user.id === promotion.id) {
                        this.selectedPromotions.splice(
                            this.selectedPromotions.indexOf(user),
                            1
                        );
                    }
                });
            }
        },
        handlePagination(pageNumber: any) {
            this.selectedPromotions = [];
            this.currentPage = pageNumber;
            var topCheckbox = document.getElementById("topCheckboxProm") as any;
            topCheckbox.checked = false;
            this.getPromotions();
        },
        getBadgeType(type: string, scope: string) {
            if (scope === "color") {
                switch (type) {
                    case "Due":
                        return "badge-due";
                    case "new_pro_member":
                        return "badge-pro-member";
                    case "Election":
                        return "badge-election";
                    case "transfer":
                        return "badge-transfer";
                    case "new_member":
                        return "badge-new-member";
                    case "Nomination":
                        return "badge-pro-status";
                    case "Promotion":
                        return "badge-promotion";
                    case "Promotion Honoraire":
                        return "badge-promotion-honoraire";
                    case "addresses":
                        return "badge-addresses";
                    case "Reinstatement":
                        return "badge-reinstatement";
                    case "Admission":
                        return "badge-admission";
                    case "Penalty":
                        return "badge-penalty";
                    case "Renewal":
                        return "badge-renewal";
                    case "adhesion":
                        return "badge-adhesion";
                    case "radiation":
                        return "badge-radiation";
                    case "omgd":
                        return "badge-omgd";
                }
            } else if (scope === "text") {
                switch (type) {
                    case "Due":
                        return "Due";
                    case "new_pro_member":
                        return "Nouveau membre Pro";
                    case "Election":
                        return "Election";
                    case "transfer":
                        return "Transfert";
                    case "new_member":
                        return "Nouveau membre";
                    case "Nomination":
                        return "Nomination";
                    case "addresses":
                        return "Adresses";
                    case "Reinstatement":
                        return "Reinstatement";
                    case "Admission":
                        return "Admission";
                    case "Penalty":
                        return "Penalty";
                    case "Promotion":
                        return "Promotion";
                    case "Promotion Honoraire":
                        return "Promotion H.";
                    case "Renewal":
                        return "Renewal";
                    case "adhesion":
                        return "Adhésion";
                    case "radiation":
                        return "Radiation";
                    case "omgd":
                        return "OMGD";
                }
            }
        },
        handleQuery(query) {
            this.promotionSearch = query;
            this.getPromotions();
        },
    },
});
