
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import membersApi from "@/core/services/MembersApi";
import dashboardApi from "@/core/services/DashboardApi";
import DashboardAll from "./DashboardAll.vue";
import DashboardAdm from "./DashboardAdm.vue";
import DashboardProm from "./DashboardProm.vue";
import DashboardSimpleRenew from "./DashboardSimpleRenew.vue";
import DashboardTrans from "./DashboardTrans.vue";
import DashboardDone from "./DashboardDone.vue";

export default defineComponent({
    name: "dashboard",
    components: {
        DashboardAll,
        DashboardAdm,
        DashboardProm,
        DashboardSimpleRenew,
        // DashboardMultipleRenew,
        DashboardTrans,
        DashboardDone,
    },
    data() {
        return {
            newMembersFrom: new Date().toDateString(),
            newMembersAt: { id: "", label: "Everywhere" },
            newMembersNb: 0,
            newMembersStats: {},
            allNb: 0,
            admNb: 0,
            promNb: 0,
            simpleRenewNb: 0,
            multipleRenewNb: 0,
            transNb: 0,
            doneNb: 0,
            duesFrom: new Date().toDateString(),
            duesAt: { id: "", label: "Everywhere" },
            newDuesNb: 0,
            newDuesStats: {},
            chapitresFrom: new Date().toDateString(),
            chapitresAt: { id: "", label: "Everywhere" },
            newChapitresNb: 0,
            newChapitresStats: {},
            contributingFrom: new Date().toDateString(),
            contributingAt: { id: "", label: "Everywhere" },
            newContributingNb: 0,
            newContributingStats: {},
            list: [] as any,
            bailliages: [] as any,
            loadingSearch: false,
            loadingNewMembers: false,
            loadingDues: false,
            loadingChapitres: false,
            loadingContributingMembers: false,
            options: [],
            fakePromotions: [],
            fakeDues: [],
            fakeOMGD: [],
            fakePending: [],
            refreshChild: false,
            perm_pos: "",
            allKey: 0,
            admKey: 0,
            promKey: 0,
            simpleRenewKey: 0,
            multipleRenewKey: 0,
            transKey: 0,
            doneKey: 0,
        };
    },
    mounted() {
        // Split the year from date in order to clean warnings
        this.newMembersFrom = this.newMembersFrom.split(" ")[3];
        this.duesFrom = this.duesFrom.split(" ")[3];
        this.chapitresFrom = this.chapitresFrom.split(" ")[3];
        this.contributingFrom = this.contributingFrom.split(" ")[3];
        this.getBailliages();
        this.perm_pos = localStorage.getItem("perm_pos") as any;
    },
    setup() {
        onMounted(() => {
            setCurrentPageBreadcrumbs("Latest requests", []);
        });
        return {};
    },
    created() {
        this.initStats();
    },
    beforeUnmount() {
        localStorage.removeItem("filtersPayload");
        localStorage.removeItem("activeFilters");
    },
    methods: {
        initStats() {
            var payload = {
                card: "new_members",
                year: new Date().getFullYear().toString(),
                bailliage: "all",
            };
            this.loadingNewMembers = true;
            this.loadingDues = true;
            this.loadingChapitres = true;
            this.loadingContributingMembers = true;

            dashboardApi.getCardsStats(payload).then((res: any) => {
                this.newMembersStats = res.data.new_members;
                payload.card = "dues";
                dashboardApi.getCardsStats(payload).then((res: any) => {
                    this.newDuesStats = res.data.dues;
                    payload.card = "chapitres";
                    dashboardApi.getCardsStats(payload).then((res: any) => {
                        this.newChapitresStats = res.data.chapitres;
                        payload.card = "contributing_members";
                        dashboardApi.getCardsStats(payload).then((res: any) => {
                            this.newContributingStats = res.data.contributing_members;
                            this.loadingNewMembers = false;
                            this.loadingDues = false;
                            this.loadingChapitres = false;
                            this.loadingContributingMembers = false;
                        });
                    });
                });
            });
        },
        getBailliages() {
            membersApi.getBailliages().then((res) => {
                res.data.map((bailliage) => {
                    if (bailliage.type === "National" || bailliage.type === "national") {
                        this.bailliages.push(bailliage);
                    }
                });
            });
        },
        refreshChildren(scope: string) {
            switch (scope) {
                case "all":
                    this.admKey++;
                    this.promKey++;
                    this.simpleRenewKey++;
                    this.multipleRenewKey++;
                    this.transKey++;
                    break;
                case "admission":
                    this.admKey++;
                    break;
                case "promotion":
                    this.promKey++;
                    break;
                case "renew":
                    this.simpleRenewKey++;
                    break;
                case "renew_multiple":
                    this.multipleRenewKey++;
                    break;
                case "transfer":
                    this.transKey++;
                    break;
            }
            this.allKey++;
            this.doneKey++;
        },
        setAllNb(value: number) {
            this.allNb = value;
        },
        setAdmNb(value: number) {
            this.admNb = value;
        },
        setPromNb(value: number) {
            this.promNb = value;
        },
        setSimpleRenewNb(value: number) {
            this.simpleRenewNb = value;
        },
        setMultipleRenewNb(value: number) {
            this.multipleRenewNb = value;
        },
        setTransNb(value: number) {
            this.transNb = value;
        },
        setDoneNb(value: number) {
            this.doneNb = value;
        },
        setCardsStats(year: any, bailliage: any, scope: string) {
            var card = "";
            switch (scope) {
                case "newMembers":
                    this.loadingNewMembers = true;
                    this.bailliages.map((item: any) => {
                        if (item.id === bailliage) this.newMembersAt.label = item.label;
                    });
                    bailliage === "" ? (this.newMembersAt.label = "") : null;
                    card = "new_members";
                    break;
                case "dues":
                    this.loadingDues = true;
                    this.bailliages.map((item: any) => {
                        if (item.id === bailliage) this.duesAt.label = item.label;
                    });
                    bailliage === "" ? (this.duesAt.label = "") : null;
                    card = "dues";
                    break;
                case "chapitres":
                    this.loadingChapitres = true;
                    this.bailliages.map((item: any) => {
                        if (item.id === bailliage) this.chapitresAt.label = item.label;
                    });
                    bailliage === "" ? (this.chapitresAt.label = "") : null;
                    card = "chapitres";
                    break;
                case "contributing":
                    this.loadingContributingMembers = true;
                    this.bailliages.map((item: any) => {
                        if (item.id === bailliage) this.contributingAt.label = item.label;
                    });
                    bailliage === "" ? (this.contributingAt.label = "") : null;
                    card = "contributing_members";
                    break;
            }

            bailliage === "all" || bailliage === null || bailliage === "" || bailliage === "Everywhere" ? (bailliage = "all") : bailliage;
            year === null ? (year = new Date().getFullYear().toString()) : year;

            const payload = {
                card,
                year,
                bailliage: bailliage,
            };

            dashboardApi.getCardsStats(payload).then((res: any) => {
                switch (scope) {
                    case "newMembers":
                        this.newMembersStats = res.data.new_members;
                        break;
                    case "dues":
                        this.newDuesStats = res.data.dues;
                        break;
                    case "chapitres":
                        this.newChapitresStats = res.data.chapitres;
                        break;
                    case "contributing":
                        this.newContributingStats = res.data.contributing_members;
                        break;
                }
            });

            setTimeout(() => {
                this.loadingNewMembers = false;
                this.loadingDues = false;
                this.loadingChapitres = false;
                this.loadingContributingMembers = false;
            }, 500);
        },
        remoteMethod(query) {
            if (query !== "") {
                this.loadingSearch = true;
                setTimeout(() => {
                    this.loadingSearch = false;
                    this.options = this.bailliages.filter((item: any) => {
                        return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
                    });
                }, 200);
            } else {
                this.options = [];
            }
        },
    },
});
