
import { defineComponent, ref } from "vue";
import dashboardApi from "@/core/services/DashboardApi";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import { Form, Field } from "vee-validate";
import { object, string } from "yup";
import { hideModal } from "@/core/helpers/dom";
import membersApi from "@/core/services/MembersApi";
import duesApi from "@/core/services/DuesApi";
import Filters from "../crafted/widgets/Filters.vue";
import FilterTags from "../crafted/widgets/FilterTags.vue";

export default defineComponent({
    name: "DashboardSimpleRenew",
    emits: ["getSimpleRenewNb", "refresh"],
    components: { Form, Field, Filters, FilterTags },
    props: {
        refreshChild: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            renew: [],
            pages: [] as any,
            totalPages: "",
            currentPage: 1,
            selectedRenew: [] as any,
            actualFilter: "",
            actualCol: "",
            loading: true,
            renewSearch: "",
            renewCount: "",
            perm_pos: "",
            perimeters: [
                {
                    label: "CDR",
                    value: "CDR",
                },
                {
                    label: "OMGD",
                    value: "OMGD",
                },
            ],
            dueLabel: "",
            memberId: "",
            disableDuePerimeter: false,
            priceCategories: [] as any,
            dueTypes: [] as any,
            price_cat_options: [] as any,
            dueAmount: "",
            disableDueBtn: false,
            disableDuePriceCategory: false,
            dueEntry: {
                id: null as any,
                member: null as any,
                relevant_year: null as any,
                perimeter: null as any,
                type: null as any,
                price_category: null as any,
                date: null as any,
                penalty: false,
            } as any,
            editingDue: false,
            activeFilters: {},
            filtersPayload: {},
            tagsKey: 0,
            filtersKey: 0,
            filtersToShow: {
                age: false,
                bailliage: false,
                compactBailliage: true,
                commander: false,
                country: false,
                dues: false,
                function: false,
                gender: false,
                grade: false,
                medal: false,
                member_type: false,
                other_association: false,
                period: true,
                postal_code: false,
                state: false,
                title: false,
                activity_sector: false,
                plaque: false,
                credit_card: false,
                institution_type: false,
                food_style: false,
                stars: false,
                price_category_promotion: false,
                price_category_due: true,
                type_due: false,
                relevant_due_year: false,
                type_chapitre: false,
                amount_accr: false,
                type_accr: false,
                biller_period: false,
                biller_balances: false,
            },
        };
    },
    setup() {
        // Number of items per page
        const resultsPerPage = 10;
        const router = useRouter();
        const renewModalRef = ref<null | HTMLElement>(null) as any;

        const closeRenewModal = () => {
            hideModal(renewModalRef.value);
        };

        const renewSchema = object().shape({
            relevantYear: string().required().label("Relevant year").nullable(),
            perimeter: string().required().label("Perimeter").nullable(),
            dueType: string().required().label("Due type").nullable(),
            priceCategory: string()
                .required()
                .label("Price category")
                .nullable(),
            dueDate: string().required().label("Due date").nullable(),
        });

        function createDebounce() {
            let timeout: any;
            return function (fnc, delayMs) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, delayMs || 500);
            };
        }

        return {
            resultsPerPage,
            router,
            debounce: createDebounce(),
            renewModalRef,
            closeRenewModal,
            renewSchema,
        };
    },
    created() {
        this.getRenew();
        this.getPriceCategories();
        this.perm_pos = localStorage.getItem("perm_pos") as any;
    },
    methods: {
        editEntry(e: any, in_linked: boolean) {
            if (this.perm_pos === "0") {
                this.memberId = e.member;
                var is_linked = false;

                if (e.resourcetype === "DueRequest") {
                    e.linked_objects.length > 0
                        ? (is_linked = true)
                        : (is_linked = false);
                    document.getElementById("openDueModalrenew")?.click();
                    duesApi
                        .getSpecificDue(
                            is_linked && in_linked
                                ? e.linked_objects[0].id
                                : e.object.id
                        )
                        .then((res: any) => {
                            if (res.success) {
                                membersApi
                                    .getDuesTypes({ p: 1, presult: 9999 })
                                    .then((resp: any) => {
                                        this.dueTypes = resp.data.object_list;
                                        this.disableDueBtn = false;
                                    });
                                this.dueAmount = res.data.price_category.amount;
                                this.dueEntry.id = res.data.id;
                                this.dueEntry.type = res.data.type.id;
                                this.dueEntry.member = res.data.member.id;
                                this.dueEntry.perimeter = res.data.perimeter;
                                this.dueEntry.price_category =
                                    res.data.price_category.id;
                                this.dueEntry.relevant_year =
                                    res.data.relevant_year;
                                this.dueEntry.date = res.data.date;
                                res.data.is_penalty
                                    ? (this.dueEntry.penalty = true)
                                    : (this.dueEntry.penalty = false);
                                this.editDue(res.data, res.data.member.id);
                            }
                        });
                }
            }
        },
        getPriceCategories() {
            membersApi
                .getPriceCategories({ p: 1, presult: 9999 })
                .then((res) => {
                    this.priceCategories = this.price_cat_options =
                        res.data.object_list;
                    this.priceCategories.sort((a: any, b: any) =>
                        a.amount > b.amount ? 1 : -1
                    );
                });
        },
        setDueAmount(e: any) {
            this.priceCategories.map((item: any) => {
                if (item.id === e) {
                    this.dueAmount = item.amount;
                }
            });
        },
        fetchPriceCategories(memberId: any) {
            var payload = {
                p: this.currentPage,
                presult: 100,
                member: memberId,
                current_selection: {
                    type: this.dueEntry.type,
                    perimeter: this.dueEntry.perimeter,
                },
            };

            if (typeof payload.current_selection.type === "string") {
                this.dueTypes.map((item: any) => {
                    if (item.label === payload.current_selection.type) {
                        payload.current_selection.type = item.id;
                    }
                });
            }

            if (
                !!payload.current_selection.type &&
                !!payload.current_selection.perimeter
            ) {
                membersApi.getPriceCategories(payload).then((response) => {
                    this.price_cat_options = response.data.object_list;
                    this.price_cat_options.sort((a: any, b: any) =>
                        a.amount > b.amount ? 1 : -1
                    );

                    if (this.price_cat_options.length === 1) {
                        this.dueEntry.price_category =
                            this.price_cat_options[0].id;
                        this.setDueAmount(this.dueEntry.price_category);

                        if (this.perm_pos !== "0")
                            this.disableDuePriceCategory = true;
                    } else {
                        this.disableDuePriceCategory = false;
                    }
                });
            }
        },
        editDue(due: any, memberId: any) {
            this.fetchPriceCategories(memberId);
            this.dueLabel = due.type.label;
            if (due.type.code === "REIN") this.disableDuePerimeter = true;
            else this.disableDuePerimeter = false;

            this.price_cat_options.map((item: any) => {
                if (item.id === due.price_category.id) {
                    this.dueEntry.price_category = item.id;
                }
            });
        },
        updateDue() {
            const dueID = this.dueEntry.id;

            delete this.dueEntry.type;
            delete this.dueEntry.id;

            membersApi.editDue(dueID, this.dueEntry).then((res: any) => {
                if (res.success) {
                    this.getRenew();
                    this.closeRenewModal();
                    ElMessage({
                        type: "success",
                        message: "Successfully edited due.",
                    });
                }
            });
        },
        goToMember(memberId: any) {
            const routeData = this.router.resolve({
                name: "member-details",
                params: { member_id: memberId, page: 1 },
            });
            window.open(routeData.href, "_blank");
        },
        emitCount() {
            this.$emit("getSimpleRenewNb", this.renewCount);
            const payload = {
                p: 1,
                presult: 99999,
                filters: {
                    tab: "renewal",
                },
            };
            dashboardApi.list(payload).then((res: any) => {
                this.renewCount = res.data.object_count;
                this.$emit("getSimpleRenewNb", this.renewCount);
                this.$emit("refresh");
            });
        },
        handleRequest(action: string, type: string, id?: number) {
            var payload;

            if (type === "row") {
                payload = [];
                this.renew.map((row: any) => {
                    if (row.id === id) {
                        row.requests.map((request: any) => {
                            payload.push(request.id);
                        });
                    }
                });
            } else {
                id ? (payload = [id]) : (payload = this.selectedRenew);
            }

            if (action === "accept") {
                dashboardApi.accept(payload).then((res: any) => {
                    if (res.success) {
                        type === "row"
                            ? this.removeRow("accepted", id)
                            : this.removeRequest(id, "accepted");
                        this.emitCount();
                    }
                });
            } else if (action === "refuse") {
                dashboardApi.refuse(payload).then((res: any) => {
                    if (res.success) {
                        type === "row"
                            ? this.removeRow("refused", id)
                            : this.removeRequest(id, "refused");
                        this.emitCount();
                    }
                });
            }
        },
        handleAllRequests(action: string) {
            var payload = [] as any;

            this.renew.map((row: any) => {
                row.requests.map((request: any) => {
                    payload.push(request.id);
                });
            });

            if (action === "accept") {
                dashboardApi.accept(payload).then((res: any) => {
                    if (res.success) {
                        this.renew = [];
                        ElMessage({
                            message: "All requests have been accepted.",
                            type: "success",
                        });
                        this.emitCount();
                    }
                });
            } else if (action === "refuse") {
                dashboardApi.refuse(payload).then((res: any) => {
                    if (res.success) {
                        this.renew = [];
                        ElMessage({
                            message: "All requests have been refused.",
                            type: "success",
                        });
                        this.emitCount();
                    }
                });
            }
        },
        removeRequest(id: any, action: string) {
            this.renew.map((row: any) => {
                row.requests = row.requests.filter(
                    (request: any) => request.id !== id
                );
                if (row.requests.length === 0)
                    this.renew = this.renew.filter(
                        (row: any) => row.requests.length !== 0
                    );
            });
            ElMessage({
                message: "Request " + action + ".",
                type: "success",
            });
        },
        removeRow(action: string, id?: any) {
            if (id) {
                this.renew = this.renew.filter((row: any) => row.id !== id);
                ElMessage({
                    message: "Requests group " + action + ".",
                    type: "success",
                });
            } else {
                this.renew = [];
                ElMessage({
                    message: "All items have been " + action + ".",
                    type: "success",
                });
            }
        },
        handleSizeChange() {
            this.getRenew();
        },
        hasAnyFilters() {
            for (const [key, value] of Object.entries(this.filtersPayload)) {
                if (value !== "") return true;
            }
            return false;
        },
        queryChanged(query: string) {
            this.renewSearch = query;
            this.getRenew();
        },
        getFiltersPayload(e: any) {
            this.tagsKey++;
            this.filtersPayload = e[0];
            this.activeFilters = e[1];

            var fp = JSON.stringify(this.filtersPayload);
            var f = JSON.stringify(this.activeFilters);

            localStorage.setItem("filtersPayload", fp);
            localStorage.setItem("activeFilters", f);

            this.getRenew();
        },
        getRenew() {
            var payload;
            this.loading = true;
            this.selectedRenew = [];
            this.renew = [];

            if (this.hasAnyFilters()) {
                if (this.actualFilter && this.actualCol) {
                    // Si filtres & tri
                    payload = {
                        p: this.currentPage,
                        presult: this.resultsPerPage,
                        status: "active",
                        filters: {
                            tab: "renewal",
                            ...this.filtersPayload,
                        },
                        column: this.actualCol,
                        order: this.actualFilter,
                        tab: "renewal",
                    };
                } else {
                    // Si filtres et pas de tri
                    payload = {
                        p: this.currentPage,
                        presult: this.resultsPerPage,
                        status: "active",
                        filters: {
                            tab: "renewal",
                            ...this.filtersPayload,
                        },
                    };
                }
            } else if (this.actualFilter && this.actualCol) {
                // Si tri et pas de filtres
                payload = {
                    p: this.currentPage,
                    presult: this.resultsPerPage,
                    status: "active",
                    column: this.actualCol,
                    order: this.actualFilter,
                    filters: {
                        tab: "renewal",
                    },
                };
            } else {
                // Sinon on affiche tout
                payload = {
                    p: this.currentPage,
                    presult: this.resultsPerPage,
                    status: "active",
                    filters: {
                        tab: "renewal",
                    },
                };
            }

            if (this.renewSearch) {
                payload = { ...payload, query: this.renewSearch };
            }
            dashboardApi.list(payload).then((res: any) => {
                this.renew = res.data.object_list;
                this.renewCount = res.data.object_count;
                this.$emit("getSimpleRenewNb", res.data.object_count);
                this.totalPages = res.data.page_count;
                this.loading = false;
            });
        },
        sortColumn(column: string, id: number) {
            var arrows = document.getElementById("chevrons-renew-" + id);

            // Hide all arrows
            Array.from(
                document.getElementsByClassName("chevrons-container") as any
            ).map((filter: any) => {
                filter.style.display = "none";
            });

            // Display the right arrows
            if (arrows) arrows.style.display = "flex";

            // If we click for the first time on a column OR if we change the column
            if (this.actualCol === "" || this.actualCol !== column) {
                this.actualCol = column;
                this.actualFilter = "asc";
                this.handleChevron("show", "up");
                this.handleChevron("hide", "down");

                // Else if we click on the same column
            } else if (this.actualCol === column) {
                if (this.actualFilter === "asc") {
                    this.handleChevron("hide", "up");
                    this.handleChevron("show", "down");
                    this.actualFilter = "desc";
                } else if (this.actualFilter === "desc") {
                    this.handleChevron("show", "up");
                    this.handleChevron("hide", "down");
                    this.actualFilter = "asc";
                }
            }
            this.getRenew();
        },
        handleChevron(action: string, direction: string) {
            var chevrons: any;

            if (direction === "up")
                chevrons = Array.from(
                    document.getElementsByClassName("fa-chevron-up") as any
                );
            else if (direction === "down")
                chevrons = Array.from(
                    document.getElementsByClassName("fa-chevron-down") as any
                );

            if (action === "show")
                chevrons.map((chevron: any) => {
                    chevron.classList.add("active-chevron");
                });
            else if (action === "hide")
                chevrons.map((chevron: any) => {
                    chevron.classList.remove("active-chevron");
                });
        },
        checkAll() {
            var topCheckbox = document.getElementById(
                "topCheckboxRenew"
            ) as any;
            var checkboxes = Array.from(
                document.getElementsByClassName("custom-checkbox-renew") as any
            );

            this.selectedRenew = [];

            if (topCheckbox.checked) {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = true;
                });
                this.renew.map((member: any) => {
                    this.selectedRenew.push(member);
                });
            } else {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = false;
                });
                this.selectedRenew = [];
            }
        },
        handleActionSelect(member: any) {
            var focusedCheckbox = document.getElementById(
                "checkbox-renew-" + member.id
            ) as any;

            if (focusedCheckbox?.checked) {
                this.selectedRenew.push(member);
            } else {
                this.selectedRenew.map((user: any) => {
                    if (user.id === member.id) {
                        this.selectedRenew.splice(
                            this.selectedRenew.indexOf(user),
                            1
                        );
                    }
                });
            }
        },
        handlePagination(pageNumber: any) {
            this.selectedRenew = [];
            this.currentPage = pageNumber;
            var topCheckbox = document.getElementById(
                "topCheckboxRenew"
            ) as any;
            topCheckbox.checked = false;
            this.getRenew();
        },
        getBadgeType(type: string, scope: string) {
            if (scope === "color") {
                switch (type) {
                    case "Due":
                        return "badge-due";
                    case "new_pro_member":
                        return "badge-pro-member";
                    case "Election":
                        return "badge-election";
                    case "transfer":
                        return "badge-transfer";
                    case "new_member":
                        return "badge-new-member";
                    case "Nomination":
                        return "badge-pro-status";
                    case "Promotion":
                        return "badge-promotion";
                    case "Promotion Honoraire":
                        return "badge-promotion-honoraire";
                    case "addresses":
                        return "badge-addresses";
                    case "Reinstatement":
                        return "badge-reinstatement";
                    case "Admission":
                        return "badge-admission";
                    case "Penalty":
                        return "badge-penalty";
                    case "Renewal":
                        return "badge-renewal";
                    case "adhesion":
                        return "badge-adhesion";
                    case "radiation":
                        return "badge-radiation";
                    case "omgd":
                        return "badge-omgd";
                }
            } else if (scope === "text") {
                switch (type) {
                    case "Due":
                        return "Due";
                    case "new_pro_member":
                        return "Nouveau membre Pro";
                    case "Election":
                        return "Election";
                    case "transfer":
                        return "Transfert";
                    case "new_member":
                        return "Nouveau membre";
                    case "Nomination":
                        return "Nomination";
                    case "addresses":
                        return "Adresses";
                    case "Reinstatement":
                        return "Reinstatement";
                    case "Admission":
                        return "Admission";
                    case "Penalty":
                        return "Penalty";
                    case "Promotion":
                        return "Promotion";
                    case "Promotion Honoraire":
                        return "Promotion H.";
                    case "Renewal":
                        return "Renewal";
                    case "adhesion":
                        return "Adhésion";
                    case "radiation":
                        return "Radiation";
                    case "omgd":
                        return "OMGD";
                }
            }
        },
        handleQuery(query) {
            this.renewSearch = query;
            this.getRenew();
        },
    },
});
