import ApiService from "./ApiService";

const LIST = "api/v1/dashboard/list-request/"
const ACCEPT = "api/v1/dashboard/accept-multiple/";
const REFUSE = "api/v1/dashboard/refuse-multiple/";
const TO_PENDING = "api/v1/dashboard/pending/";
const GET_STATS = "api/v1/dashboard/stat-card/";
const EXPORT_STATS = "api/v1/member/stats/";

export default {
    list(payload) {
        ApiService.setHeader();
        return ApiService.post(LIST, payload).then(response => {
            return response.data;
        });
    },
    accept(payload) {
        ApiService.setHeader();
        return ApiService.post(ACCEPT, payload).then(response => {
            return response.data;
        });
    },
    refuse(payload) {
        ApiService.setHeader();
        return ApiService.post(REFUSE, payload).then(response => {
            return response.data;
        });
    },
    moveToPending(payload) {
        ApiService.setHeader();
        return ApiService.post(TO_PENDING, payload).then(response => {
            return response.data;
        });
    },
    getCardsStats(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_STATS, payload).then(response => {
            return response.data;
        });
    },
    exportStats(payload) {
        ApiService.setHeader();
        return ApiService.post(EXPORT_STATS, payload).then(response => {
            return response.data;
        });
    },
};
