import apiTool from "./index";
import ApiService from "./ApiService";

const GET_DUES = "api/v1/cotisation/list/";
const ADD_DUES = "api/v1/cotisation/add-multiple/";
const DELETE_DUE = "api/v1/cotisation/delete/";
const CANCEL_DUE = "api/v1/cotisation/cancel/";
const GET_SPECIFIC_DUE = "api/v1/cotisation/<pk>";

export default {
    getDues(payload) {
        ApiService.setHeader();
        return ApiService.post(GET_DUES, payload).then(response => {
            return response.data;
        });
    },
    getSpecificDue(id) {
        ApiService.setHeader();
        return ApiService.get(GET_SPECIFIC_DUE.replace("<pk>", id)).then(response => {
            return response.data;
        });
    },
    addDues(payload) {
        ApiService.setHeader();
        return ApiService.post(ADD_DUES, payload).then(response => {
            return response.data;
        });
    },
    deleteDues(payload) {
        ApiService.setHeader();
        return ApiService.post(DELETE_DUE, payload).then(response => {
            return response;
        });
    },
    cancelDue(payload) {
        ApiService.setHeader();
        return ApiService.post(CANCEL_DUE, payload).then(response => {
            return response;
        });
    }
};
