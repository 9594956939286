import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "nav nav-tabs nav-line-tabs nav-line-tabs-3x mb-5 fs-6",
  id: "dashboard-tabs"
}
const _hoisted_2 = { class: "nav-item" }
const _hoisted_3 = {
  class: "nav-link h-100 active",
  "data-bs-toggle": "tab",
  href: "#all"
}
const _hoisted_4 = {
  key: 0,
  class: "badge badge-square badge-secondary ms-2 text-success fw-boldest"
}
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = {
  class: "nav-link h-100",
  "data-bs-toggle": "tab",
  href: "#adm"
}
const _hoisted_7 = {
  key: 0,
  class: "badge badge-square badge-secondary ms-2 text-success fw-boldest"
}
const _hoisted_8 = { class: "nav-item" }
const _hoisted_9 = {
  class: "nav-link h-100",
  "data-bs-toggle": "tab",
  href: "#prom"
}
const _hoisted_10 = {
  key: 0,
  class: "badge badge-square badge-secondary ms-2 text-success fw-boldest"
}
const _hoisted_11 = { class: "nav-item" }
const _hoisted_12 = {
  class: "nav-link h-100",
  "data-bs-toggle": "tab",
  href: "#simple-renew"
}
const _hoisted_13 = {
  key: 0,
  class: "badge badge-square badge-secondary ms-2 text-success fw-boldest"
}
const _hoisted_14 = {
  key: 0,
  class: "nav-item"
}
const _hoisted_15 = {
  class: "nav-link h-100 no-action",
  "data-bs-toggle": "tab",
  href: "#multiple-renew"
}
const _hoisted_16 = {
  key: 0,
  class: "badge badge-square badge-secondary ms-2 text-success fw-boldest"
}
const _hoisted_17 = { class: "nav-item" }
const _hoisted_18 = {
  class: "nav-link h-100",
  "data-bs-toggle": "tab",
  href: "#trans"
}
const _hoisted_19 = {
  key: 0,
  class: "badge badge-square badge-secondary ms-2 text-success fw-boldest"
}
const _hoisted_20 = { class: "nav-item" }
const _hoisted_21 = {
  class: "nav-link h-100",
  "data-bs-toggle": "tab",
  href: "#doneItems"
}
const _hoisted_22 = {
  key: 0,
  class: "badge badge-square badge-secondary ms-2 text-success fw-boldest"
}
const _hoisted_23 = {
  class: "tab-content",
  id: "dashboardTabs"
}
const _hoisted_24 = {
  class: "tab-pane fade show active",
  id: "all",
  role: "tabpanel",
  "aria-labelledby": "all-tab"
}
const _hoisted_25 = {
  class: "tab-pane fade",
  id: "adm",
  role: "tabpanel",
  "aria-labelledby": "adm-tab"
}
const _hoisted_26 = {
  class: "tab-pane fade",
  id: "prom",
  role: "tabpanel",
  "aria-labelledby": "prom-tab"
}
const _hoisted_27 = {
  class: "tab-pane fade",
  id: "simple-renew",
  role: "tabpanel",
  "aria-labelledby": "simple-renew-tab"
}
const _hoisted_28 = {
  class: "tab-pane fade",
  id: "trans",
  role: "tabpanel",
  "aria-labelledby": "trans-tab"
}
const _hoisted_29 = {
  class: "tab-pane fade",
  id: "doneItems",
  role: "tabpanel",
  "aria-labelledby": "done-tab"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardAll = _resolveComponent("DashboardAll")!
  const _component_DashboardAdm = _resolveComponent("DashboardAdm")!
  const _component_DashboardProm = _resolveComponent("DashboardProm")!
  const _component_DashboardSimpleRenew = _resolveComponent("DashboardSimpleRenew")!
  const _component_DashboardTrans = _resolveComponent("DashboardTrans")!
  const _component_DashboardDone = _resolveComponent("DashboardDone")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("ul", _hoisted_1, [
      _createElementVNode("li", _hoisted_2, [
        _createElementVNode("a", _hoisted_3, [
          _createTextVNode("All requests"),
          (_ctx.allNb !== 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.allNb), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("li", _hoisted_5, [
        _createElementVNode("a", _hoisted_6, [
          _createTextVNode("Admissions"),
          (_ctx.admNb !== 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.admNb), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("li", _hoisted_8, [
        _createElementVNode("a", _hoisted_9, [
          _createTextVNode("Promotions "),
          (_ctx.promNb !== 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.promNb), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("li", _hoisted_11, [
        _createElementVNode("a", _hoisted_12, [
          _createTextVNode("Renewals "),
          (_ctx.simpleRenewNb !== 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.simpleRenewNb), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      (1 === 0)
        ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
            _createElementVNode("a", _hoisted_15, [
              _createTextVNode("Renewals (Multiple) "),
              (_ctx.multipleRenewNb !== 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.multipleRenewNb), 1))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("li", _hoisted_17, [
        _createElementVNode("a", _hoisted_18, [
          _createTextVNode("Transfers "),
          (_ctx.transNb !== 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.transNb), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("li", _hoisted_20, [
        _createElementVNode("a", _hoisted_21, [
          _createTextVNode("Done "),
          (_ctx.doneNb !== 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_ctx.doneNb), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_23, [
      _createElementVNode("div", _hoisted_24, [
        (_openBlock(), _createBlock(_component_DashboardAll, {
          onGetAllNb: _ctx.setAllNb,
          onRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.refreshChildren('all'))),
          key: _ctx.allKey
        }, null, 8, ["onGetAllNb"]))
      ]),
      _createElementVNode("div", _hoisted_25, [
        (_openBlock(), _createBlock(_component_DashboardAdm, {
          onGetAdmNb: _ctx.setAdmNb,
          onRefresh: _cache[1] || (_cache[1] = ($event: any) => (_ctx.refreshChildren('admission'))),
          key: _ctx.admKey
        }, null, 8, ["onGetAdmNb"]))
      ]),
      _createElementVNode("div", _hoisted_26, [
        (_openBlock(), _createBlock(_component_DashboardProm, {
          onGetPromNb: _ctx.setPromNb,
          onRefresh: _cache[2] || (_cache[2] = ($event: any) => (_ctx.refreshChildren('promotion'))),
          key: _ctx.promKey
        }, null, 8, ["onGetPromNb"]))
      ]),
      _createElementVNode("div", _hoisted_27, [
        (_openBlock(), _createBlock(_component_DashboardSimpleRenew, {
          onGetSimpleRenewNb: _ctx.setSimpleRenewNb,
          onRefresh: _cache[3] || (_cache[3] = ($event: any) => (_ctx.refreshChildren('renew'))),
          key: _ctx.simpleRenewKey
        }, null, 8, ["onGetSimpleRenewNb"]))
      ]),
      _createElementVNode("div", _hoisted_28, [
        (_openBlock(), _createBlock(_component_DashboardTrans, {
          onGetTransNb: _ctx.setTransNb,
          onRefresh: _cache[4] || (_cache[4] = ($event: any) => (_ctx.refreshChildren('transfer'))),
          key: _ctx.transKey
        }, null, 8, ["onGetTransNb"]))
      ]),
      _createElementVNode("div", _hoisted_29, [
        (_openBlock(), _createBlock(_component_DashboardDone, {
          onGetDoneNb: _ctx.setDoneNb,
          onRefresh: _cache[5] || (_cache[5] = ($event: any) => (_ctx.refreshChildren('done'))),
          key: _ctx.doneKey
        }, null, 8, ["onGetDoneNb"]))
      ])
    ])
  ], 64))
}