import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "d-flex mt-5 flex-wrap" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_auto_animate = _resolveDirective("auto-animate")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeFilters, (filter) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "filter-tag",
        key: filter.id
      }, [
        _createElementVNode("p", {
          class: "my-0 text-main",
          style: _normalizeStyle(_ctx.isErasable ? 'margin-right: 0.5rem' : ''),
          innerHTML: filter.label
        }, null, 12, _hoisted_2),
        (_ctx.isErasable)
          ? (_openBlock(), _createElementBlock("i", {
              key: 0,
              class: "bi bi-x-circle-fill text-main",
              onClick: ($event: any) => (_ctx.removeFilter(filter))
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ])), [
    [_directive_auto_animate, { duration: 175 }]
  ])
}