
import { defineComponent } from "vue";
import dashboardApi from "@/core/services/DashboardApi";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import Filters from "../crafted/widgets/Filters.vue";
import FilterTags from "../crafted/widgets/FilterTags.vue";

export default defineComponent({
    name: "DashboardDone",
    emits: ["getDoneNb", "refresh"],
    props: {
        refreshChild: {
            type: Boolean,
            default: false,
        },
    },
    components: { Filters, FilterTags },
    data() {
        return {
            doneItems: [],
            pages: [] as any,
            totalPages: "",
            currentPage: 1,
            selectedDone: [] as any,
            actualFilter: "",
            actualCol: "",
            loading: true,
            doneSearch: "",
            doneCount: "",
            perm_pos: "",
            activeFilters: {},
            filtersPayload: {},
            tagsKey: 0,
            filtersKey: 0,
            filtersToShow: {
                age: false,
                bailliage: false,
                compactBailliage: true,
                commander: false,
                country: false,
                dues: false,
                function: false,
                gender: false,
                grade: false,
                medal: false,
                member_type: false,
                other_association: false,
                period: true,
                postal_code: false,
                state: false,
                title: false,
                activity_sector: false,
                plaque: false,
                credit_card: false,
                institution_type: false,
                food_style: false,
                stars: false,
                price_category_promotion: false,
                price_category_due: true,
                type_due: false,
                relevant_due_year: false,
                type_chapitre: false,
                amount_accr: false,
                type_accr: false,
                biller_period: false,
                biller_balances: false,
            },
        };
    },
    setup() {
        // Number of items per page
        const resultsPerPage = 10;
        const router = useRouter();

        function createDebounce() {
            let timeout: any;
            return function (fnc, delayMs) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, delayMs || 500);
            };
        }

        return { resultsPerPage, router, debounce: createDebounce() };
    },
    created() {
        this.getDone();
        this.emitCount();
        this.perm_pos = localStorage.getItem("perm_pos") as any;
    },
    methods: {
        goToMember(memberId: any) {
            const routeData = this.router.resolve({
                name: "member-details",
                params: { member_id: memberId, page: 1 },
            });
            window.open(routeData.href, "_blank");
        },
        emitCount() {
            this.$emit("getDoneNb", this.doneCount);
            const payload = {
                p: 1,
                presult: 99999,
                filters: {
                    tab: "done",
                },
            };
            dashboardApi.list(payload).then((res: any) => {
                this.doneCount = res.data.object_count;
                this.$emit("getDoneNb", this.doneCount);
            });
        },
        handleSizeChange() {
            this.getDone();
        },
        hasAnyFilters() {
            for (const [key, value] of Object.entries(this.filtersPayload)) {
                if (value !== "") return true;
            }
            return false;
        },
        queryChanged(query: string) {
            this.doneSearch = query;
            this.getDone();
        },
        getFiltersPayload(e: any) {
            this.tagsKey++;
            this.filtersPayload = e[0];
            this.activeFilters = e[1];

            var fp = JSON.stringify(this.filtersPayload);
            var f = JSON.stringify(this.activeFilters);

            localStorage.setItem("filtersPayload", fp);
            localStorage.setItem("activeFilters", f);

            this.getDone();
        },
        getDone() {
            var payload;
            this.loading = true;
            this.selectedDone = [];
            this.doneItems = [];

            if (this.hasAnyFilters()) {
                if (this.actualFilter && this.actualCol) {
                    // Si filtres & tri
                    payload = {
                        p: this.currentPage,
                        presult: this.resultsPerPage,
                        status: "active",
                        filters: {
                            tab: "done",
                            ...this.filtersPayload,
                        },
                        column: this.actualCol,
                        order: this.actualFilter,
                        tab: "done",
                    };
                } else {
                    // Si filtres et pas de tri
                    payload = {
                        p: this.currentPage,
                        presult: this.resultsPerPage,
                        status: "active",
                        filters: {
                            tab: "done",
                            ...this.filtersPayload,
                        },
                    };
                }
            } else if (this.actualFilter && this.actualCol) {
                // Si tri et pas de filtres
                payload = {
                    p: this.currentPage,
                    presult: this.resultsPerPage,
                    status: "active",
                    column: this.actualCol,
                    order: this.actualFilter,
                    filters: {
                        tab: "done",
                    },
                };
            } else {
                // Sinon on affiche tout
                payload = {
                    p: this.currentPage,
                    presult: this.resultsPerPage,
                    status: "active",
                    filters: {
                        tab: "done",
                    },
                };
            }

            if (this.doneSearch) {
                payload = { ...payload, query: this.doneSearch };
            }
            dashboardApi.list(payload).then((res: any) => {
                this.doneItems = res.data.object_list;
                this.doneCount = res.data.object_count;
                this.emitCount();
                this.totalPages = res.data.page_count;
                this.loading = false;
            });
        },
        removeRequest(id: any, action: string) {
            this.doneItems.map((row: any) => {
                row.requests = row.requests.filter(
                    (request: any) => request.id !== id
                );
                if (row.requests.length === 0)
                    this.doneItems = this.doneItems.filter(
                        (row: any) => row.requests.length !== 0
                    );
            });
            ElMessage({
                message: "Request " + action + ".",
                type: "success",
            });
        },
        removeRow(action: string, id?: any) {
            if (id) {
                this.doneItems = this.doneItems.filter(
                    (row: any) => row.id !== id
                );
                ElMessage({
                    message: "Requests group " + action + ".",
                    type: "success",
                });
            } else {
                this.doneItems = [];
                //this.getDoneItems();
                ElMessage({
                    message: "All items have been " + action + ".",
                    type: "success",
                });
            }
        },
        sortColumn(column: string, id: number) {
            var arrows = document.getElementById("chevrons-pending-" + id);

            // Hide all arrows
            Array.from(
                document.getElementsByClassName("chevrons-container") as any
            ).map((filter: any) => {
                filter.style.display = "none";
            });

            // Display the right arrows
            if (arrows) arrows.style.display = "flex";

            // If we click for the first time on a column OR if we change the column
            if (this.actualCol === "" || this.actualCol !== column) {
                this.actualCol = column;
                this.actualFilter = "asc";
                this.handleChevron("show", "up");
                this.handleChevron("hide", "down");

                // Else if we click on the same column
            } else if (this.actualCol === column) {
                if (this.actualFilter === "asc") {
                    this.handleChevron("hide", "up");
                    this.handleChevron("show", "down");
                    this.actualFilter = "desc";
                } else if (this.actualFilter === "desc") {
                    this.handleChevron("show", "up");
                    this.handleChevron("hide", "down");
                    this.actualFilter = "asc";
                }
            }
            //this.getDone();
        },
        handleChevron(action: string, direction: string) {
            var chevrons: any;

            if (direction === "up")
                chevrons = Array.from(
                    document.getElementsByClassName("fa-chevron-up") as any
                );
            else if (direction === "down")
                chevrons = Array.from(
                    document.getElementsByClassName("fa-chevron-down") as any
                );

            if (action === "show")
                chevrons.map((chevron: any) => {
                    chevron.classList.add("active-chevron");
                });
            else if (action === "hide")
                chevrons.map((chevron: any) => {
                    chevron.classList.remove("active-chevron");
                });
        },
        checkAll() {
            var topCheckbox = document.getElementById("topCheckboxDone") as any;
            var checkboxes = Array.from(
                document.getElementsByClassName("custom-checkbox-done") as any
            );

            this.selectedDone = [];

            if (topCheckbox.checked) {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = true;
                });
                this.doneItems.map((item: any) => {
                    this.selectedDone.push(item.id);
                });
            } else {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = false;
                });
                this.selectedDone = [];
            }
        },
        handleActionSelect(selectedDone: any) {
            var focusedCheckbox = document.getElementById(
                "checkbox-done-" + selectedDone.id
            ) as any;

            if (focusedCheckbox?.checked) {
                this.selectedDone.push(selectedDone);
            } else {
                this.selectedDone.map((done: any) => {
                    if (done.id === selectedDone.id) {
                        this.selectedDone.splice(
                            this.selectedDone.indexOf(done),
                            1
                        );
                    }
                });
            }
        },
        handlePagination(pageNumber: any) {
            this.selectedDone = [];
            this.currentPage = pageNumber;
            var topCheckbox = document.getElementById("topCheckboxDone") as any;
            if (topCheckbox) topCheckbox.checked = false;
            this.getDone();
        },
        redirectTo(memberId: any) {
            const routeData = this.router.resolve({
                name: "member-details",
                params: { member_id: memberId },
            });
            window.open(routeData.href, "_blank");
        },
        getBadgeType(type: string, scope: string) {
            if (scope === "color") {
                switch (type) {
                    case "Due":
                        return "badge-due";
                    case "new_pro_member":
                        return "badge-pro-member";
                    case "Election":
                        return "badge-election";
                    case "Transfer":
                        return "badge-transfer";
                    case "new_member":
                        return "badge-new-member";
                    case "Nomination":
                        return "badge-pro-status";
                    case "Promotion":
                        return "badge-promotion";
                    case "Promotion Honoraire":
                        return "badge-promotion-honoraire";
                    case "addresses":
                        return "badge-addresses";
                    case "Reinstatement":
                        return "badge-reinstatement";
                    case "Admission":
                        return "badge-admission";
                    case "Penalty":
                        return "badge-penalty";
                    case "Renewal":
                        return "badge-renewal";
                    case "adhesion":
                        return "badge-adhesion";
                    case "radiation":
                        return "badge-radiation";
                    case "omgd":
                        return "badge-omgd";
                }
            } else if (scope === "text") {
                switch (type) {
                    case "Due":
                        return "Due";
                    case "new_pro_member":
                        return "Nouveau membre Pro";
                    case "Election":
                        return "Election";
                    case "Transfer":
                        return "Transfer";
                    case "new_member":
                        return "Nouveau membre";
                    case "Nomination":
                        return "Nomination";
                    case "addresses":
                        return "Adresses";
                    case "Reinstatement":
                        return "Reinstatement";
                    case "Admission":
                        return "Admission";
                    case "Penalty":
                        return "Penalty";
                    case "Promotion":
                        return "Promotion";
                    case "Promotion Honoraire":
                        return "Promotion H.";
                    case "Renewal":
                        return "Renewal";
                    case "adhesion":
                        return "Adhésion";
                    case "radiation":
                        return "Radiation";
                    case "omgd":
                        return "OMGD";
                }
            }
        },
        handleQuery(query) {
            this.doneSearch = query;
            this.getDone();
        },
    },
});
